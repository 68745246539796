// Search.
import './search.js';

window.addEventListener(
	'load',
	() => {
		// Functions
		// disableDepartureField();

		// Search
		// searchObjects();
	},
	{
		passive: true,
	}
);
