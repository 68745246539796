/**
 * Search objects
 */
const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
    let [resource, config ] = args;

    let response = await originalFetch(resource, config);

	if ( resource.includes( 'GetFreeObjects' ) ) {
		const json = () =>
			response
				.clone()
				.json()
				.then((data) => { searchObjects( data ) });

			response.json = json;
	}

    // response interceptor here
    return response;
};

function searchObjects(objects) {
	const objectsHTML = document.querySelector('.row.objects');
	const xhrFormData = new FormData();

	objectsHTML.classList.add('bb-xhr-loading');

	xhrFormData.append('action', 'basticom_bookzo_xhr_search_objects');
	xhrFormData.append('nonce', basticom_bookzo_xhr.nonce);
	xhrFormData.append('objects', JSON.stringify(objects.Result));

	fetch(basticom_bookzo_xhr.ajaxurl, {
		method: 'POST',
		body: xhrFormData,
		cache: 'default',
	})
		.then((response) => response.json())
		.then((data) => {
			document.querySelector( '.row.objects' ).innerHTML = data.html;
		})
		.catch(console.error)
		.finally(() => {
			objectsHTML.classList.remove('bb-xhr-loading');
		});
}
